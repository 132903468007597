import { render, staticRenderFns } from "./collegeList.vue?vue&type=template&id=05c5613e&scoped=true&"
import script from "./collegeList.vue?vue&type=script&lang=js&"
export * from "./collegeList.vue?vue&type=script&lang=js&"
import style0 from "./collegeList.vue?vue&type=style&index=0&lang=css&"
import style1 from "./collegeList.vue?vue&type=style&index=1&id=05c5613e&scoped=true&lang=css&"
import style2 from "./collegeList.vue?vue&type=style&index=2&lang=css&"
import style3 from "./collegeList.vue?vue&type=style&index=3&id=05c5613e&lang=scss&scoped=true&"
import style4 from "./collegeList.vue?vue&type=style&index=4&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c5613e",
  null
  
)

export default component.exports