import { ref, watch, computed,onMounted,nextTick } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

//firebase
import firebase from "@/utils/firebaseInit"
import firebaseTimeStamp from "firebase"
import {dbCollections} from "@/utils/firebaseCollection"
import { CodeIcon } from 'vue-feather-icons'
const db = firebase.firestore()
const months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import { getCollectionDataTypesense } from '@/utils/Typesense/queries'
import { updateRootCollectionDataByDocId } from '@/utils/FirebaseQueries/updateQueries/updateQueries'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'no', sortable: false },
    { key: 'college_name', sortable: true },
    { key: 'university_name', sortable: true },
    { key: 'no_of_course',label:"No. of Course" , sortable: false },
    { key: 'city', sortable: true },
    { key: 'state', sortable: true },
    { key: 'country', sortable: true },
    { key: 'actions' }
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(null)
  const isSpinner = ref(null)


  const filterCountry = ref(null)
  const filterState = ref(null)
  const filterCity = ref(null)

  //NEW
  const collegeArray = ref([])
  const isFetching = ref(false)
  const previuosDisabled = ref(false)
  const nextDisabled = ref(false)
  const firstDoc = ref(null)
  const lastDoc = ref(null)
  const docCategoryTable  = ref(null)
  const fetchMoreDataStop = ref(false)
  const sortContect = ref(null)
  const timeFnction = ref(null)

  const dataMeta = computed(() => {
      const localItemsCount = docCategoryTable.value ? docCategoryTable.value.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalUsers.value,
      }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }
  onMounted(()=>{
    nextTick(()=>{
      // fetchCollege()
      fetchCollegeFromTypesense(true,true,()=>{refetchData()})
    })
  })

  
  watch([currentPage], () => {
    refetchData()
  })
  watch([perPage,filterCountry,filterState,filterCity], () => {
    fetchCollegeFromTypesense(false,false,()=>{refetchData()})
  })
  

  const fetchCollegeFromTypesense = (resetResult = true,manageData=false,callback) => {
    try {
      if(resetResult){
        isFetching.value = true;
      }
      if(manageData == true) {
        currentPage.value = 1;
        sortBy.value = '';
        isSortDirDesc.value = null;
        sortContect.value = null;
      }
      let searchParameters = {
        collectionName: `${dbCollections.COLLEGES}`,
        search: {
            q:"*",
            page: currentPage.value,
            per_page: perPage.value,
        }
      }

      let filter = ``

      if (filterCountry.value !== null && filterCountry.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `country.name:=${filterCountry.value.name}`
      }
      if (filterState.value !== null && filterState.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `state.name:=${filterState.value.name}`
      }
      if (filterCity.value !== null && filterCity.value !== '') {
          if(filter !== '') {
              filter += ` && `
          }
          filter += `city.name:=${filterCity.value.name}`
      }
      if(filter !== '' && filter !== null) {
        searchParameters.search.filter_by = filter;
      }
      if (sortContect.value) {
        searchParameters.search.sort_by = `${(sortContect.value.sortBy && sortContect.value.sortBy !== '') ? `${sortContect.value.sortBy}: ${sortContect.value.sortDesc == false ? 'asc' : 'desc'}` : ''}`;
      }
      if(searchQuery.value !== '' && searchQuery.value !== null) {
        searchParameters.search.q = searchQuery.value;
        searchParameters.search.query_by = 'collegeName,universityName,country.name,state.name,city.name';
      }
        getCollectionDataTypesense(searchParameters).then(async(result)=>{
            if(result.hits.length) {
              totalUsers.value = result.found
                const arrayData = await Promise.all(result.hits.map(async(x) => {
                    const row = x.document;
                    let serchQuery = {
                      collectionName: `${dbCollections.COURSES}`,
                      search: {
                          q: "*",
                          filter_by: `collegeId:=${row.id}`,
                          per_page: 1,
                      }
                    };
                
                    const ele = await getCollectionDataTypesense(serchQuery);

                    return {
                      'id': row.id,
                      'isDeleted': row.isDeleted || false,
                      'pincode': `${row.pincode}`,
                      'college_name': row.collegeName,
                      'universityId': row.universityId,
                      'university_name': row.universityName,
                      'no_of_course': ele.found,
                      'address': `${row.addressLine1} ${row.addressLine2 ? ', ' : ''}${row.addressLine2}, ${row.pincode}`,
                      'city': row.city,
                      'state': row.state,
                      'country': row.country,
                    };
                }));
                collegeArray.value = arrayData;
                isFetching.value = false;
                callback(arrayData)
            } else {
              collegeArray.value = [];
              totalUsers.value = 0;
              callback([])
              isFetching.value = false;
            }
        }).catch((error) => {
          collegeArray.value = [];
          totalUsers.value = 0;
          callback([])
          console.error(error)
          isFetching.value = false;
        })
    } catch (error) {
      collegeArray.value = [];
      totalUsers.value = 0;
      callback([])
      console.error(error)
    }
  }
  const handlePageChange = (newPage) => {
      currentPage.value = newPage;
      fetchCollegeFromTypesense(false,false,()=>{refetchData()})
  }
  function handleSearch() {
    clearTimeout(timeFnction.value);
    timeFnction.value = setTimeout(() => {
      fetchCollegeFromTypesense(false,false,()=>{refetchData()});
    }, 1000);
  }
  const handleSortChange = (context) => {
    let sortFieldObject = {
        university_name : "universityName",
        college_name : "collegeName",
        no_of_course : "collegeName",
        address : "",
        city : "city.name",
        state : "state.name", 
        country : "country.name", 
        actions : "", 
    }
    const {sortBy,sortDesc} = context
    sortContect.value = {sortBy:sortFieldObject[sortBy],sortDesc};
    currentPage.value = 1;
    fetchCollegeFromTypesense(true,false,()=>{refetchData()})
  }
  // var usersList = []
  // var users = []
  // const fetchUsers = (ctx, callback) => {
  //   users = []
  //   console.log("get List")
  //   isSpinner.value = true
  //   if(usersList.length==0 && isSpinner.value)
  //   {
  //     db
  //       .collection(dbCollections.COLLEGES)
  //       .orderBy('createdAt')
  //       .get()
  //       .then(querySnapshot=>{
  //         if(!querySnapshot.empty)
  //         {
  //           var startIndex = (currentPage.value-1)*perPage.value+1
  //           var endIndex = startIndex+perPage.value-1
  //           var index = 1
  //           var dataIndex = 1
  //           console.log(startIndex,endIndex)
  //           querySnapshot.forEach(doc=>{

  //             //Turn isDeleted Flag = false
  //             // db
  //             //   .collection(dbCollections.COLLEGES)
  //             //   .doc(doc.id)
  //             //   .update({
  //             //     isDeleted:false
  //             //   })
  //             //   .catch(error => console.log(error))
             
  //             //if(!doc.data().isDeleted){
  //                 if(doc.data().universityId !== undefined && doc.data().universityId !== ""){  // JYOTI YADAV Code
  //                   db.collection(dbCollections.UNIVERSITIES).doc(doc.data().universityId).onSnapshot((uniDoc) => { //get university data [jyoti]
  //                     let datas = doc.data()
  //                     datas.universityName = uniDoc.data().universityName ? uniDoc.data().universityName : doc.data().universityName
  //                     // if(!doc.data().isDeleted)
  //                     // {
  //                       let findCollegeIndex = usersList.findIndex((e)=>e.id == datas.id)
  //                       if(findCollegeIndex == -1) {
  //                         usersList.push(datas)
  //                         usersList[usersList.length-1].college_name=doc.data().collegeName
  //                         usersList[usersList.length-1].address=`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`
  //                         usersList[usersList.length-1].university_name=uniDoc.data().universityName ? uniDoc.data().universityName : doc.data().universityName
  //                         usersList[usersList.length-1].no_of_course=doc.data().courses
  //                       } else {
  //                         if(uniDoc.data().isDeleted === true) {
  //                           usersList[findCollegeIndex] = {...usersList[findCollegeIndex],...doc.data(),college_name:doc.data().collegeName,address:`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`,university_name:uniDoc.data().universityName ? uniDoc.data().universityName : doc.data().universityName,no_of_course:doc.data().courses,isDeleted: true}
  //                         } else {
  //                           usersList[findCollegeIndex] = {...usersList[findCollegeIndex],college_name:doc.data().collegeName,address:`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`,university_name:uniDoc.data().universityName ? uniDoc.data().universityName : doc.data().universityName,no_of_course:doc.data().courses}
  //                         }
  //                       }
  //                     // }
                      
  //                     if(dataIndex>=startIndex && dataIndex<=endIndex)
  //                     {
  //                     let cllgObj = {
  //                         'id':doc.data().id,
  //                         'isDeleted':doc.data().isDeleted||false,
  //                         'universityId':doc.data().universityId,  
  //                         'college_name':doc.data().collegeName,
  //                         'address':`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`,
  //                         'university_name':uniDoc.data().universityName ? uniDoc.data().universityName : doc.data().universityName,
  //                         'no_of_course':doc.data().courses,
  //                         'city':doc.data().city,
  //                         'state':doc.data().state,
  //                         'country':doc.data().country,
  //                       }      
  //                       let cIndex = users.findIndex((e)=>e.id == doc.data().id)
  //                       if(cIndex == -1) {
  //                         users.push(cllgObj) 
  //                       } else {
  //                         if(uniDoc.data().isDeleted === true) {
  //                           users[cIndex] = {...users[cIndex],...cllgObj,isDeleted:true} 
  //                         } else {
  //                           users[cIndex] = {...users[cIndex],...cllgObj} 
  //                         }
  //                       }
  //                       dataIndex++;
  //                     }
  //                     if(index==querySnapshot.size)
  //                     {
  //                       totalUsers.value = usersList.length
  //                       isSpinner.value = false
  //                       callback(users)
  //                     }
  //                     index++;
                
  //                   })
  //                 }else{
  //                     //SHIV JOSHI CODE START...  
  //                   // if(!doc.data().isDeleted)
  //                   // {
  //                     usersList.push(doc.data())
  //                   // }
  //                   if(dataIndex>=startIndex && dataIndex<=endIndex)
  //                   {
  //                     let cllgObj = {
  //                       'id':doc.data().id,
  //                       'isDeleted':doc.data().isDeleted||false,
  //                       'universityId':doc.data().universityId,  
  //                       'college_name':doc.data().collegeName,
  //                       'address':`${doc.data().addressLine1} ${doc.data().addressLine2?', ':''}${doc.data().addressLine2}, ${doc.data().pincode}`,
  //                       'university_name':doc.data().universityName,
  //                       'no_of_course':doc.data().courses,
  //                       'city':doc.data().city,
  //                       'state':doc.data().state,
  //                       'country':doc.data().country,
  //                     }
  //                     console.log(doc.data().universityId,"users")                         
  //                     users.push(cllgObj) 
  //                     dataIndex++;
  //                   }
  //                   if(index==querySnapshot.size)
  //                   {
  //                     totalUsers.value = usersList.length
  //                     isSpinner.value = false
  //                     callback(users)
  //                   }
  //                   index++;
  //                   //SJ END
  //                 }
  //            // }
              
  //           })
  //         }
  //         else
  //         {
  //           totalUsers.value = querySnapshot.size
  //           isSpinner.value = false
  //           callback(users)
  //         }
  //       })
  //       .catch(error => {
  //         isSpinner.value = false
  //         console.log(error)
  //       })
  //   }
  //   else
  //   {
  //     console.log("Already has list data")
  //     var startIndex = (currentPage.value-1)*perPage.value+1
  //     var endIndex = startIndex+perPage.value-1
  //     var index = 1
  //     // var dataIndex = 1
  //     console.log(startIndex,endIndex)
  //     if(usersList.length==0)
  //     {
  //       totalUsers.value = usersList.length
  //       isSpinner.value=false
  //       callback(users)
  //       return;
  //     }

  //     if(isSortDirDesc.value==false)
  //     {
  //       console.log("Asc")
  //       if(sortBy.value=='city')
  //       {
  //         usersList.sort((a, b) => (a.city.name > b.city.name ? 1 : -1))//Asc
  //       }
  //       else if(sortBy.value=='state')
  //       {
  //         usersList.sort((a, b) => (a.state.name > b.state.name ? 1 : -1))//Asc
  //       }
  //       else if(sortBy.value=='country')
  //       {
  //         usersList.sort((a, b) => (a.country.name > b.country.name ? 1 : -1))//Asc
  //       }
  //       else
  //       {
  //         usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? 1 : -1))//Asc
  //       }
  //     }
  //     else if(isSortDirDesc.value==true)
  //     {
  //       console.log("Desc")
  //       if(sortBy.value=='city')
  //       {
  //         usersList.sort((a, b) => (a.city.name > b.city.name ? -1 : 1))//Desc
  //       }
  //       else if(sortBy.value=='state')
  //       {
  //         usersList.sort((a, b) => (a.state.name > b.state.name ? -1 : 1))//Desc
  //       }
  //       else if(sortBy.value=='country')
  //       {
  //         usersList.sort((a, b) => (a.country.name > b.country.name ? -1 : 1))//Desc
  //       }
  //       else
  //       {
  //         usersList.sort((a, b) => (a[sortBy.value] > b[sortBy.value] ? -1 : 1))//Desc
  //       }
  //     }
      
  //     usersList.forEach(data=>{
  //       if(searchQuery.value=='' && filterCountry.value==null && filterState.value==null && filterCity.value==null)
  //       {
  //         if(index>=startIndex && index<=endIndex)
  //         {
  //           users.push({
  //             'id':data.id,
  //             'isDeleted':data.isDeleted||false,
  //             'universityId':data.universityId,      
  //             'college_name':data.collegeName,
  //             'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
  //             'university_name':data.universityName,
  //             'no_of_course':data.courses,
  //             'city':data.city,
  //             'state':data.state,
  //             'country':data.country,
  //           })
  //           // dIndex++;
  //         }
  //         if(index==usersList.length)
  //         {
  //           // if(currentPage.value > 1 && users.length==0)
  //           // {
  //           //   currentPage.value -= currentPage.value
  //           // }
  //           totalUsers.value = usersList.length
  //           isSpinner.value = false
  //           callback(users)
  //         }
  //         index++;
  //       }
  //       else if(data.isDeleted !== undefined)
  //       {
  //         users.push({
  //           'id':data.id,
  //           'isDeleted':data.isDeleted||false,
  //           'universityId':data.universityId,  
  //           'college_name':data.collegeName,
  //           'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
  //           'university_name':data.universityName,
  //           'no_of_course':data.courses,
  //           'city':data.city,
  //           'state':data.state,
  //           'country':data.country,
  //         })

  //         if(index==usersList.length)
  //         {
  //           var finalArr = users;
  //           var tmp = [];

  //           var dataIndex=1
  //           if(searchQuery.value!='')
  //           {
  //             finalArr.forEach(UD=>{
  //               if(
  //                 searchQuery.value && UD.college_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.university_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.city.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.state.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
  //                 searchQuery.value && UD.no_of_course.toString().toLowerCase().includes(searchQuery.value.toLowerCase())
  //               )
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //Country Filter
  //           if(filterCountry.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterCountry.value.name == UD.country.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //State Filter
  //           if(filterState.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterState.value.name == UD.state.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }
  //           //City Filter
  //           if(filterCity.value!=null)
  //           {
  //             finalArr.forEach(UD=>{
  //               if(filterCity.value.name == UD.city.name)
  //               {
  //                 tmp.push(UD)
  //               }
  //             })
  //             finalArr=tmp
  //             tmp=[]
  //           }

  //           console.log("finalArr",finalArr)

  //           //Final Table Data
  //           finalArr.forEach(FD=>{
  //             if(dataIndex>=startIndex && dataIndex<=endIndex)
  //             {
  //               tmp.push(FD)
  //             }
  //             dataIndex++
  //           })
  //           // if(currentPage.value > 1 && users.length==0)
  //           // {
  //           //   currentPage.value -= currentPage.value
  //           // }
  //           totalUsers.value = finalArr.length
  //           isSpinner.value = false
  //           callback(tmp)
  //         }
  //         index++;
  //       }
  //       else
  //       {
  //         isSpinner.value = false
  //         callback(users)
  //       }
  //     })
  //   }

  //   // for (let index = 0; index < 10; index++) {
  //   //   users.push({
  //   //     'No':index,
  //   //     'Date of Inquiry':`Date ${index}`,
  //   //     'Student Name':`Name ${index}`,
  //   //     'Phone no.':9462489464654,
  //   //     'College Name.':`Test`,
  //   //     'Branch.':`abcd`,
  //   //     'CGPA(%).':(index+1*(Math.random()*100)%100).toFixed(2),
  //   //     'actions':`<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create Account </b-button> <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="btn-icon" > Create New Inquiry </b-button>`
  //   //   })
  //   // }
  //   // callback(users)
  // }

  //NEW

  async function fetchCollege(direction = '', refetchData = false) {
    try {
      if (!refetchData) {
        console.log('fetchCollege START', new Date().getTime());
        isSpinner.value = true;
        isFetching.value = true;
        collegeArray.value = [];
      }
  
      let whereQueries = [];
      let limit = 100;
      let orderByValue = { field: "createdAt", value: 1 };
      let refDoc;
      let refDoc1;
      let limitToLast = 0;
  
      if (direction === 'next') {
        orderByValue = { field: "createdAt", value: 1 };
        refDoc = { doc: lastDoc.value };
      } else if (direction === 'previous') {
        orderByValue = { field: "createdAt", value: 1 };
        refDoc1 = { doc: firstDoc.value };
        limitToLast = limit;
      }
  
      const querySnapshot = await getCollectionData(`${dbCollections.COLLEGES}`, whereQueries, orderByValue, limit, '', '', refDoc?.doc || '', refDoc1?.doc || '', limitToLast);
  
      if (querySnapshot.empty) {
        isSpinner.value = false;
        isFetching.value = false;
  
        if (direction === 'next') {
          nextDisabled.value = true;
          lastDoc.value = '';
        } else if (direction === 'previous') {
          previuosDisabled.value = true;
          firstDoc.value = '';
        }
        return;
      }
  
      const docs = querySnapshot.docs?.map((x) => ({ ...x.data(), id: x.id }));
      const users = await Promise.all(docs.map(async (doc) => {
        let serchQuery = {
          collectionName: `${dbCollections.COURSES}`,
          search: {
              q: "*",
              filter_by: `collegeId:=${doc.id}`,
              per_page: 1,
          }
        };
    
        // Wrap the entire await inside the map function
        const ele = await getCollectionDataTypesense(serchQuery);
        const userDetail = {
          'id': doc.id,
          'isDeleted': doc.isDeleted || false,
          'pincode': `${doc.pincode}`,
          'college_name': doc.collegeName,
          'universityId': doc.universityId,
          'university_name': doc.universityName,
          'no_of_course': ele.found,
          'address': `${doc.addressLine1} ${doc.addressLine2 ? ', ' : ''}${doc.addressLine2}, ${doc.pincode}`,
          'city': doc.city,
          'state': doc.state,
          'country': doc.country,
        };
        return userDetail;
    }));
  
      if (direction === 'next') {
        lastDoc.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      } else if (direction === 'previous') {
        firstDoc.value = querySnapshot.docs[0];
      }
  
      if (!refetchData) {
        console.log('fetchCollege END', new Date().getTime());
      }
  
      collegeArray.value = [...collegeArray.value, ...users];
      lastDoc.value = querySnapshot.docs[querySnapshot.docs.length - 1];
      firstDoc.value = querySnapshot.docs[0];
      totalUsers.value = collegeArray.value.length;
      isSpinner.value = false;
      isFetching.value = false;
  
      if (nextDisabled.value === false && fetchMoreDataStop.value === false) {
        fetchCollege('next', true);
      }
    } catch (error) {
      isFetching.value = false;
      isSpinner.value = false;
      console.error("Error in fetchCollege", error);
    }
  }
  
  const filteredCollgeList = computed(()=>{
      var finalArr = collegeArray.value;
      var tmp = [];

      if(searchQuery.value!='')
      {
        finalArr.forEach(UD=>{
          if(
            searchQuery.value && UD.college_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            searchQuery.value && UD.university_name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            searchQuery.value && UD.city.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            searchQuery.value && UD.country.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            searchQuery.value && UD.state.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            searchQuery.value && UD.no_of_course.toString().toLowerCase().includes(searchQuery.value.toLowerCase())
          )
          {
            tmp.push(UD)
          }
        })
        finalArr=tmp
        tmp=[]
      }
      //Country Filter
      if(filterCountry.value!=null)
      {
        finalArr.forEach(UD=>{
          if(filterCountry.value.name == UD.country.name)
          {
            tmp.push(UD)
          }
        })
        finalArr=tmp
        tmp=[]
      }
      //State Filter
      if(filterState.value!=null)
      {
        finalArr.forEach(UD=>{
          if(filterState.value.name == UD.state.name)
          {
            tmp.push(UD)
          }
        })
        finalArr=tmp
        tmp=[]
      }
      //City Filter
      if(filterCity.value!=null)
      {
        finalArr.forEach(UD=>{
          if(filterCity.value.name == UD.city.name)
          {
            tmp.push(UD)
          }
        })
        finalArr=tmp
        tmp=[]
      }
    return finalArr;
  })

  const createCollege = (data, callback)=>{
    db
      .collection(dbCollections.COLLEGES)
      .add({
        id:'',
        isDeleted:false,
        createdAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

        collegeName:data.collegeName,
        universityName:data.universityName,
        city:data.city,
        state:data.state,
        country:data.country,
        universityId:data.universityId,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        pincode:data.pincode,
        
        courses:[],
      })
      .then((docRef)=>{
        db
          .collection(dbCollections.COLLEGES)
          .doc(docRef.id)
          .update({
            id:docRef.id,
            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
          })
          .then(()=>{
            let updateObject = {
              'id':docRef.id,
              'isDeleted':false,
              'addressLine1':data.addressLine1,
              'addressLine2':data.addressLine2,
              'pincode':data.pincode,
              'course':0,
              'city':data.city,
              'state':data.state,
              'country':data.country,
              'college_name':data.collegeName,
              'universityId':data.universityId,
              'university_name':data.universityName,
              'no_of_course':data.courses || 0,
              'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
            }
            if(currentPage.value == 1) {
              if(sortContect.value != null) {
                fetchCollegeFromTypesense(false,true,()=>{
                  let index = collegeArray.value.findIndex((e)=>e.id === docRef.id);
                  if(index == -1) {
                    collegeArray.value.unshift({
                      ...updateObject
                    })
                  }
                  totalUsers.value = totalUsers.value + 1;
                  refetchData()
                })
              } else {
                collegeArray.value.unshift({
                  ...updateObject
                })
                totalUsers.value = totalUsers.value + 1;
              }
            } else {
              fetchCollegeFromTypesense(false,true,()=>{
                let index = collegeArray.value.findIndex((e)=>e.id === docRef.id);
                if(index == -1) {
                  collegeArray.value.unshift({
                    ...updateObject
                  })
                }
                totalUsers.value = totalUsers.value + 1;
                refetchData()
              })
            }
            callback(true)
            refetchData()
          })
          .catch(error =>{
            callback(false)
          })
      })
      .catch(error =>{
        callback(false)
      })
  }

  const refreshPage = (callback)=>{
    refetchData()
    callback(true)
  }

  const editCollege = (data, callback)=>{
    db
      .collection(dbCollections.COLLEGES)
      .doc(data.id)
      .update({
        updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

        collegeName:data.collegeName,
        universityName:data.universityName,
        city:data.city,
        state:data.state,
        country:data.country,
        universityId:data.universityId,
        addressLine1:data.addressLine1,
        addressLine2:data.addressLine2,
        pincode:data.pincode,
      })
      .then(()=>{
        let fIndex = collegeArray.value.findIndex((e)=>e.id == data.id);
        if(fIndex !== -1) {
          collegeArray.value[fIndex] = {
            ...collegeArray.value[fIndex],
            'isDeleted':false,
            'addressLine1':data.addressLine1,
            'addressLine2':data.addressLine2,
            'pincode':data.pincode,
            'city':data.city,
            'state':data.state,
            'country':data.country,
            'college_name':data.collegeName,
            'universityId':data.universityId,
            'university_name':data.universityName,
            'address':`${data.addressLine1} ${data.addressLine2?', ':''}${data.addressLine2}, ${data.pincode}`,
          }
        }
        callback(true)
        refetchData()
      })
      .catch(error =>{
        callback(false)
      })
  }
  
  const deleteField = (ctx, callback)=>{    
      if(ctx.item.isDeleted == true) {
          db.collection(dbCollections.COLLEGES).doc(ctx.item.id).update({
            isDeleted:!ctx.item.isDeleted,
            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
          })
          .then(()=>{
            if(ctx.enableUniversity == undefined) {
              let findIndex = collegeArray.value.findIndex((e)=>e.id == ctx.item.id);
              if(findIndex !== -1) {
                collegeArray.value[findIndex] = {...collegeArray.value[findIndex],'isDeleted': !ctx.item.isDeleted}
              }
              callback({'msg':'college enable successfully',opt:true})
              refetchData()
            } else {
              let findIndex1 = collegeArray.value.findIndex((e)=>e.id == ctx.item.id);
              if(findIndex1 !== -1) {
                collegeArray.value[findIndex1] = {...collegeArray.value[findIndex1],'isDeleted': !ctx.item.isDeleted}
              }
              refetchData()
              db.collection(dbCollections.UNIVERSITIES).doc(ctx.item.universityId).update({
                isDeleted:false,
                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
              })
              .then(()=>{
                callback({'msg':'college and university enable successfully',opt:true})
              })
              .catch((err)=>{
                callback({'msg':err,opt:false})
              })
            }
          })
          .catch(error=>{
            callback({'msg':error,opt:false})
          })
      } else {
        db.collection(dbCollections.COLLEGES).doc(ctx.item.id).update({
          isDeleted:!ctx.item.isDeleted,
          updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
        })
        .then(()=>{
          db.collection(dbCollections.COURSES)
          .where('collegeId', '==', ctx.item.id)
          .get()
          .then((querySnapshot) => {
              let count = 0
              const updateFunction = (doc) => {
                if(count >= querySnapshot.docs.length) {
                  return;
                } else {
                  updateRootCollectionDataByDocId(dbCollections.COURSES,doc.id,{ isDeleted: true,updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp()},()=>{
                    count++;
                    updateFunction(querySnapshot.docs[count]);
                  })
                }
              }
              updateFunction(querySnapshot.docs[count]);
          })
          // .then(()=> {
              // let serchQuery = {
              //   collectionName: `${dbCollections.COURSES}`,
              //   search: {
              //       q: "*",
              //       filter_by: `collegeId:=${ctx.item.id}`,
              //       per_page: 250,
              //   }
              // };
              // getCollectionDataTypesense(serchQuery).then((response)=>{
              //   if(response.hits.length) {
              //     console.log(response.hits);
              //     let count = 0
              //     const updateFunction = (hit) => {
              //       if(count >= response.hits.length) {
              //         return;
              //       } else {
              //         updateTypesenseCollection(dbCollections.COURSES,{isDeleted:true},hit.document.id).then((updatedDocument)=>{
              //           console.log(`Document ${updatedDocument.id} updated successfully STEP 2`);
              //           count++;
              //           updateFunction(response.hits[count]);
              //         })
              //         .catch(error => {
              //           console.error(`Error updating document ${hit.id}:`, error);
              //           count++;
              //           updateFunction(response.hits[count]);
              //         });
              //       }
              //     }
              //     updateFunction(response.hits[count]);
              //   }
              // })
              // .catch(error => {
              //   console.error('Error searching documents:', error);
              // });
          // })
          .catch((error)=> {
              console.error("Error updating documents: ", error);
              callback({'msg':error,opt:false})
          });
          let findIndex = collegeArray.value.findIndex((e)=>e.id == ctx.item.id);
          if(findIndex !== -1) {
            collegeArray.value[findIndex] = {...collegeArray.value[findIndex],'isDeleted': !ctx.item.isDeleted}
          }
          callback({'msg':'college disable successfully',opt:true})
          refetchData()
        })
        .catch(error=>{
          callback({'msg':error,opt:false})
        })
      }
    }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteField,
    createCollege,
    editCollege,

    isSpinner,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    filterCountry,
    filterState,
    filterCity,
    refreshPage,
    // fetchCollege,
    collegeArray,
    filteredCollgeList,
    nextDisabled,
    isFetching,
    docCategoryTable,
    fetchMoreDataStop,
    fetchCollegeFromTypesense,
    handleSortChange,
    handlePageChange,
    handleSearch
  }
}
