import client from '../../typesenseConfig.js';
import schemas from '../schema/index.js'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'

/******* QUERY CREATE COLLECTION IN TYPESENSE  *******************/

// const collectionName = `application`
// checkCollection({
//     collectionName,
//     schemaType: "application",
//     autoCreate: true
// })
// .then(() => {})
// .catch((error) => {
//     console.error("ERROR in create application collection: ", error);
// })

export const createCollection = (body) => {
    return new Promise((resolve, reject) => {
        try {
            let keys = ["schemaType", "collectionName"];
            let bodyKeys = Object.keys(body);

            keys.forEach((key) => {
                if(!bodyKeys.includes(key)) {
                    reject(`${key} is required`)
                    return;
                }
            })

            const {collectionName, schemaType} = body;

            if(typeof schemaType !== "string")  {
                reject(new Error("schemaType must be a string"));
                return;
            } else if(schemaType === "")  {
              reject(new Error("schemaType is required"));
              return;
            }

            if(typeof collectionName !== "string")  {
                reject(new Error("collectionName must be a string"));
                return;
            } else if(collectionName === "")  {
              reject(new Error("collectionName is required"));
              return;
            }

            let schema = schemas[schemaType];

            client.collections().create({...schema, name: collectionName}).then(() => {
                resolve("Collection Created!");
            }).catch((error2) => {
                reject({"ERROR in create collection in TYPESENSE:": error2.message, error: error2});
            });
        } catch (error) {
            reject(error);
        }
    });
};

/******* QUERY CHECK COLLECTION IN TYPESENSE  *******************/
export const checkCollection = (body) => {
    return new Promise((resolve, reject) => {
        try {
            let keys = ["collectionName", "schemaType"];
            let bodyKeys = Object.keys(body);

            keys.forEach((key) => {
                if(!bodyKeys.includes(key)) {
                    reject(`${key} is required`)
                    return;
                }
            })

            const {collectionName, schemaType} = body;
            const autoCreate = body.autoCreate || true;

            if(typeof collectionName !== "string")  {
                reject(new Error("collectionName must be a string"));
                return;
            } else if(collectionName === "")  {
                reject(new Error("collectionName is required"));
                return;
            }

            if(typeof schemaType !== "string")  {
                reject(new Error("schemaType must be a string"));
                return;
            } else if(schemaType === "")  {
                reject(new Error("schemaType is required"));
                return;
            }

            client.collections(collectionName).retrieve()
            .then(() => {
                resolve("Collection found!");
            })
            .catch((error) => {
                if(autoCreate) {
                    createCollection({collectionName, schemaType})
                    .then(() => {
                        resolve(true);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                } else {
                    reject(error);
                }
            })
        } catch (error) {
            reject(error);
        }
    });
};

//ADD PROPERTY DOC DATA
export const addTaskCollection = (collectionName,objDatas) =>{
    return new Promise((resolve, reject)  => {
        try {
            client.collections(collectionName).documents().create(objDatas).then((resData) => {
                resolve(resData);
            }).catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
};


//UPDATE PROPERTY DOC DATA
export const updateTypesenseCollection = (collectionName,objDatas,id) =>{
    return new Promise((resolve, reject)  => {
        try {
            client.collections(collectionName).documents(id).update({"updatedAt": new Date().getTime()/1000, ...objDatas}).then((resData) => {
                resolve(resData);
            }).catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
};

/******* QUERIES TO GET ANY DOC  *******************/
export const getCollectionDataTypesense = (body) => {
    return new Promise((resolve, reject) => {
        try {
            let keys = ["collectionName"];
            let bodyKeys = Object.keys(body);

            keys.forEach((key) => {
                if(!bodyKeys.includes(key)) {
                    reject(`${key} is required`)
                    return;
                }
            })
            const {collectionName, docId, search} = body;

            if(typeof collectionName !== "string")  {
                reject(new Error("collectionName must be a string"));
                return;
            } else if(collectionName === "")  {
              reject(new Error("collectionName is required"));
              return;
            }

            if(docId && typeof docId !== "string")  {
                reject(new Error("docId must be a string"));
                return;
            } else if(docId && docId === "")  {
              reject(new Error("docId is required"));
              return;
            }

            if(search && typeof search !== "object")  {
                reject(new Error("search must be a object"));
                return;
            } else if(search && !Object.keys(search).length)  {
              reject(new Error("search is required"));
              return;
            }

            let query = client.collections(collectionName)
            if(docId) {
                query = query.documents(docId);
            } else {
                query = query.documents();
            }

            if(search) {
                query.search(search)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
            } else {
                query.retrieve()
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
            }

        } catch (error) {
            reject(error);
        }
    })
}
/******* QUERIES TO GET THE DATA MORE THEN THE PAGE LIMIT ANY DOC  *******************/
export const typesenseFetchAll = (collection, dataArray,total, params,isGroupBy = false, cb) => {
    try {
        return new Promise((resolve, reject) => {
            let count = 2;
            let totalFound = total;
            let finalArray = dataArray;
            const countFun = () =>{
                if(finalArray.length >= totalFound) {
                cb({
                    finalArray: finalArray
                });
                } else {
                const searchParameters = {
                    ...params,
                    page: count
                }
                client.collections(collection)
                    .documents()
                    .search(searchParameters)
                    .then((result) => {
                        let resultData = isGroupBy ? result?.grouped_hits : result?.hits || [];
                        if(resultData){
                            finalArray = finalArray.concat(resultData);
                            count++;
                            countFun();
                            resolve();
                        } else {
                            cb({finalArray: finalArray});
                            reject();
                        }
                    }).catch((error) =>{
                        console.error(error);
                        cb({finalArray: finalArray});
                        reject();
                    })
                }
            }
            countFun();
        });
    } catch (e) {
        console.error(e);
    }
}
// getQuery.getDataFromRootCollection(dbCollections.DOCUMENTS,response=>{ 
//     syncDataToTypesense(collectionName, response);
// });

// // Function to sync data to Typesense
// async function syncDataToTypesense(collectionName, data) {
//   try {
//     const records = data.map(item => ({
//       id: item.id,

//     }));

//     await client.collections(collectionName).documents().import(records);
//   } catch (error) {
//     console.error('Error syncing data to Typesense:', error);
//   }
// }
