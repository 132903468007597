import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()

export const getCollectionData = (path, whereConditions=[], orderBy={}, limit=0, startAt='', endAt='', startAfter='', endBefore='',limitToLast=0) => {
    return new Promise((resolve, reject) => {
        try {
            if(!path)  {
                reject(new Error("Path is required"));
                return;
            }

            let splitPath = path.split("/");

            let isCollection = splitPath.length % 2 !== 0;

            let query = db;
            if(splitPath.length) {
                splitPath.forEach((value, index) => {
                    if(index % 2 !== 0) {
                        query = query.doc(value);
                    } else {
                        query = query.collection(value);
                    }
                })
            }

            if(isCollection) {

                if(Object.keys(orderBy).length) {
                    query = query.orderBy(orderBy.field, orderBy.value === 0 ? "asc" : "desc");
                }
                if(startAt) {
                    query = query.startAt(startAt);
                }
                if(endAt) {
                    query = query.endAt(endAt);
                }
                if(endBefore) {
                    query = query.endBefore(endBefore);
                }
                if(startAfter) {
                    query = query.startAfter(startAfter);
                }
                if(limit !== 0) {
                    query = query.limit(limit);
                }
                if(limitToLast !== 0) {
                    query = query.limitToLast(limitToLast);
                }

                if(whereConditions.length) {
                    whereConditions.forEach((obj) => {
                        query = query.where(obj.field, obj.operation, obj.value);
                    })
                }
            }
            query
            .get()
            .then((getDocData) => {
                resolve(getDocData);
            })
            .catch((error) => {
                reject(error);
            })
        } catch (error) {
            reject(error);
        }
    })
}