<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">
      <!-- <user-list-add-new
        :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
        :role-options="roleOptions"
        :plan-options="planOptions"
        @refetch-data="refetchData"
      /> -->

      <!-- Filters -->
      <b-card
        title="Filters"
      >
        <b-row class="tableFilters">
          <b-col md="2">
            <b-form-group label="Country">
              <b-form-select
                  v-model="filterCountry"
                  :options="countryOptions"
                  placeholder="Select"
                  @change="getFilterStates()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="State">
              <b-form-select
                  v-model="filterState"
                  :options="stateOptions"
                  placeholder="Select"
                  :disabled="!filterCountry"
                  @change="getFilterCities()"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="City">
              <b-form-select
                v-model="filterCity"
                :options="cityOptions"
                placeholder="Select"
                :disabled="!filterState"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"            
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  @input="handleSearch"
                />
                <!-- <b-button
                  variant="primary"
                  @click="isAddNewUserSidebarActive = true"
                >
                  <span class="text-nowrap">Add User</span>
                </b-button> -->
              </div>
            </b-col>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <!-- <label>entries</label> -->
            </b-col>
          </b-row>

        </div>

        <b-table
          ref="refUserListTable"
          class="position-relative"
          responsive
          :fields="tableColumns"
          :current-page="1"
          :per-page="perPage"
          v-model='docCategoryTable'
          :items="collegeArray"
          primary-key="id"
          :show-empty="!isSpinner"
          no-local-sorting
          hover
          :tbody-tr-class="rowClass"
          empty-text="No matching records found"
          @row-clicked="handleRowClick"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          @sort-changed="handleSortChange"
        >
          <!-- Column: No. -->
          <template #cell(no)="data">
            <div>
                <span>{{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}</span>
            </div>
          </template>

          <!-- Column: No. of Course -->
          <template #cell(no_of_course)="data">
            <div>
                <span v-if="data.item.no_of_course">{{data.item.no_of_course}}</span>
                <span v-else>0</span>
            </div>
          </template>

          <!-- Column: City -->
          <template #cell(city)="data">
            <div style="white-space: nowrap" v-if="data.item.city">
              {{data.item.city.name}}
            </div>
          </template>

          <!-- Column: State -->
          <template #cell(state)="data">
            <div style="white-space: nowrap" v-if="data.item.state">
              {{data.item.state.name}}
            </div>
          </template>

          <!-- Column: Country -->
          <template #cell(country)="data">
            <div style="white-space: nowrap" v-if="data.item.country">
              {{data.item.country.name}}
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div style="white-space: nowrap;display:flex;justify-content: end;align-items: center;" v-if="$route.meta.action=='write' || $route.meta.action==undefined" @click.stop.prevent>
              <img src='@/assets/images/erflog/Edit.png' @click.prevent="getData(data),selectedCollege=data" v-if="!data.item.isDeleted" v-b-modal.edit-college class="action_icon"/>
              <!-- <img src='@/assets/images/erflog/disable_new.png' v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedCollege=data" v-b-modal.delete-college class="action_icon"/>
              <img src='@/assets/images/erflog/enable_new.png' v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedCollege=data" v-b-modal.delete-college class="action_icon"/> -->
              <b-icon-toggle-off class="action_icon focusRemoveToggle" variant="secondary" width="30px" height="22px" v-if="data.item.isDeleted" style="cursor:pointer !important" @click.prevent="selectedCollege=data" v-b-modal.delete-college></b-icon-toggle-off>
              <b-icon-toggle-on class="action_icon focusRemoveToggle" variant="success" width="30px" height="22px" v-if="!data.item.isDeleted" style="cursor:pointer" @click.prevent="selectedCollege=data" v-b-modal.delete-college></b-icon-toggle-on>
            </div>
            <div v-else>
              --
            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalUsers"
                @change="handlePageChange"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <!-- Create College -->
      <b-modal
        id="create-college"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Create College"
        :no-close-on-backdrop="true"
        @hidden="selectedCollege='',resetData()"
        @ok="(e)=>{e.preventDefault();createNewCollege(e);}" 
        :cancel-disabled='modalLoader || gettingUniversityList'
        :ok-disabled="modalLoader || gettingUniversityList"      
      >
        <validation-observer ref="personalInfoForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalLoader || gettingUniversityList" large class="pageLoadSpinners"/>
            <b-row>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="College Name">
                  <validation-provider
                      #default="{ errors }"
                      name="college name"
                      rules="required"
                  >
                      <b-form-input
                          v-model="collegeName"
                          :state="errors.length > 0 ? false:null"
                          :disabled="modalLoader || gettingUniversityList"
                          placeholder="Enter Text"
                          @blur="collegeName=collegeName.trim()"
                      />
                      <small class="text-danger" v-if="!duplicateCollegeName">{{ errors[0] }}</small>
                      <small class="text-danger" v-else>College already exists.</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="University Name">
                  <validation-provider
                      #default="{ errors }"
                      name="University name"
                      rules="required"
                  >
                      <b-form-select
                        v-model="universityName"
                        value-field="id" 
                        :disabled="modalLoader || gettingUniversityList"
                        text-field="name"
                        :options="universityOptionsAdd"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Text"
                      />
                      <!-- <v-select
                      :value="universityName"
                      v-model="universityName" 
                      :options="universityOptions" 
                      :reduce="val => val.id"
                      placeholder="Enter Text"
                      :state="errors.length > 0 ? false:null"
                      />  -->
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Address Line 1 -->
              <b-col md="6">
                  <b-form-group label="Address Line 1">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 1"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine1"
                          :disabled="modalLoader || gettingUniversityList"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine1=addressLine1.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Address Line 2 -->
              <b-col md="6">
                  <b-form-group label="Address Line 2">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 2"
                      rules=""
                  >
                      <b-form-input
                          v-model="addressLine2"
                          :disabled="modalLoader || gettingUniversityList"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine2=addressLine2.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Country -->
              <b-col md="6">
                  <b-form-group label="Country">
                  <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                  >
                      <b-form-select
                          v-model="country"
                          :disabled="modalLoader || gettingUniversityList"
                          :options="countryOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          @change="getStates()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                  <b-form-group label="State">
                  <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                  >
                      <b-form-select
                          v-model="state"
                          :options="stateOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          :disabled="modalLoader || gettingUniversityList || !country"
                          @change="getCities()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                  <b-form-group label="City">
                  <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                  >
                      <b-form-select
                          v-model="city"
                          :disabled="modalLoader || gettingUniversityList || !state"
                          :options="cityOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Pincode -->
              <b-col md="6">
                  <b-form-group label="Pincode">
                  <validation-provider
                      #default="{ errors }"
                      name="pincode"
                      rules="required"
                  >
                      <b-form-input
                        v-model="pincode"
                        :disabled="modalLoader || gettingUniversityList"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Pincode"
                        @blur="pincode=pincode.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>


      <!-- Edit College -->
      <b-modal
        id="edit-college"
        cancel-variant="outline-secondary"
        ok-title="Save"
        cancel-title="Cancel"
        centered
        size="lg"
        title="Edit College"
        :no-close-on-backdrop="true"
        @hidden="resetData(),selectedCollege=''"
        @ok="(e)=>{e.preventDefault();editSelectedCollege(e);}" 
        :cancel-disabled='modalLoader'  
        :ok-disabled="modalLoader"    
      >
        <validation-observer ref="personalInfoForm">
          <!-- form -->
          <b-form>
            <b-spinner variant="primary" v-if="modalLoader" large class="pageLoadSpinners"/>
            <b-row>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="College Name">
                  <validation-provider
                      #default="{ errors }"
                      name="college name"
                      rules="required"
                  >
                      <b-form-input
                          v-model="collegeName"
                          :disabled="true"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="collegeName=collegeName.trim()"
                      />
                      <!-- <small class="text-danger" v-if="!duplicateEditCollegeName">{{ errors[0] }}</small>
                      <small class="text-danger" v-else>College already exists.</small> -->
                  </validation-provider>
                  </b-form-group>
              </b-col>
              <!-- Name -->
              <b-col md="6">
                  <b-form-group label="University Name">
                  <validation-provider
                      #default="{ errors }"
                      name="university name"
                      rules="required"
                  >
                      <b-form-select
                        v-model="universityName"
                        :disabled="true"
                        value-field="id" 
                        text-field="name"
                        :options="universityOptions"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Text"
                      />
                      <!-- <v-select
                      v-model="universityName"                    
                      :options="universityOptions"
                      :clearable="false"
                      :reduce="val => val.id"
                      placeholder="Enter Text"
                      />  -->
                      <small class="text-danger" >{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- Address Line 1 -->
              <b-col md="6">
                  <b-form-group label="Address Line 1">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 1"
                      rules="required"
                  >
                      <b-form-input
                          v-model="addressLine1"
                          :disabled="modalLoader"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine1=addressLine1.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Address Line 2 -->
              <b-col md="6">
                  <b-form-group label="Address Line 2">
                  <validation-provider
                      #default="{ errors }"
                      name="address line 2"
                      rules=""
                  >
                      <b-form-input
                          v-model="addressLine2"
                          :disabled="modalLoader"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Enter Text"
                          @blur="addressLine2=addressLine2.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Country -->
              <b-col md="6">
                  <b-form-group label="Country">
                  <validation-provider
                      #default="{ errors }"
                      name="country"
                      rules="required"
                  >
                      <b-form-select
                          v-model="country"
                          :options="countryOptions"
                          :disabled="modalLoader"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          @change="getStates()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- State -->
              <b-col md="6">
                  <b-form-group label="State">
                  <validation-provider
                      #default="{ errors }"
                      name="state"
                      rules="required"
                  >
                      <b-form-select
                          v-model="state"
                          :options="stateOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                          :disabled="modalLoader && !country"
                          @change="getCities()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- City -->
              <b-col md="6">
                  <b-form-group label="City">
                  <validation-provider
                      #default="{ errors }"
                      name="city"
                      rules="required"
                  >
                      <b-form-select
                          v-model="city"
                          :disabled="modalLoader && !state"
                          :options="cityOptions"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Select"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>

              <!-- Pincode -->
              <b-col md="6">
                  <b-form-group label="Pincode">
                  <validation-provider
                      #default="{ errors }"
                      name="pincode"
                      rules="required"
                  >
                      <b-form-input
                        v-model="pincode"
                        :disabled='modalLoader'
                        :state="errors.length > 0 ? false:null"
                        placeholder="Enter Pincode"
                        @blur="pincode=pincode.trim()"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>

      <!-- Delete College -->
      <b-modal
        id="delete-college"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedCollege=''"
        v-if="selectedCollege!=''"
        @ok="deleteFieldData(selectedCollege)"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/Approve_Popup.png" alt="">
      </div>
        <div style="text-align: center;" v-if="selectedCollege!=''">
          <label class="s_modal_title" v-if="selectedCollege.item.isDeleted == true">Enable College</label>
          <label class="s_modal_title" v-if="selectedCollege.item.isDeleted == false">Disable College</label>
          <p v-if="selectedCollege.item.isDeleted == true">Are you sure you want to Enable {{selectedCollege.item.field_name}}?</p>
          <p v-if="selectedCollege.item.isDeleted == false">Are you sure you want to Disable {{selectedCollege.item.field_name}}?</p>
        </div>
      </b-modal>
      <!-- Delete College -->
      <b-modal
        id="delete-college-confirmation"
        cancel-variant="outline-secondary"
        ok-title="Yes"
        cancel-title="No"
        centered
        title=""
        :no-close-on-backdrop="true"
        @hidden="selectedCollegeConfairmation=null"
        @ok="deleteFieldDataAfterConfirm(selectedCollegeConfairmation)"
      >
      <div class="create_account_img">
        <img src="@/assets/images/erflog/Approve_Popup.png" alt="">
      </div>
        <div style="text-align: center;" v-if="selectedCollegeConfairmation!=null">
          <label class="s_modal_title" v-if="selectedCollegeConfairmation.item.isDeleted == true">Enable College</label>
          <p>{{selectedCollegeConfairmation.item.college_name}} is currently associated with the disabled {{selectedCollegeConfairmation.item.university_name}}. To enable this university, please proceed accordingly</p>
          <p>Are you sure you want to enable this {{selectedCollegeConfairmation.item.university_name}}?</p>
          <p>Choose 'Yes' to enable both, or 'No' to disabled both.</p>
        </div>
      </b-modal>
      <importModel 
      :openImportMOdel='openImportMOdel' 
      :modelTitle="modelTitle" 
      :fileName='fileName' 
      :row='row'
      :ReportTitle='ReportTitle'
      @closeImportCsvModel='closeModel'
      @importCsvFile='importCsvFile'
      :fieldError='fieldError'
      />
    </div>  
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormCheckbox,BSpinner,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormGroup,BFormRadio,BFormDatepicker,BIconToggleOff,BIconToggleOn,
  BForm,BFormTextarea,BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useUsersList from './useCollegeList'
//vee-validator
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate'

import { Country, State, City }  from 'country-state-city';

import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import firebase from '@/utils/firebaseInit'
import { dbCollections } from '@/utils/firebaseCollection'

const db = firebase.firestore()

import Ripple from 'vue-ripple-directive'

import { ValidationFunction } from '@/utils/globalValidations.js';

import importModel from '@/views/components/importModel.vue'

import { importCSVData } from '@/utils/importExportFiles/importFunction.js'
import firebaseTimeStamp from "firebase"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import * as inserQuery from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as updateQuery from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import { getCollectionData } from '@/utils/dynamicGetDataQuery'
import { getCollectionDataTypesense, typesenseFetchAll } from '@/utils/Typesense/queries'
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider, 
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormRadio,
    BFormDatepicker,
    BFormTextarea,
    BFormSelect,
    BFormCheckbox,
    vSelect,
    importModel,
    BSpinner,
    BIconToggleOn,
    BIconToggleOff
  },
  data(){
    return{
      collegeName:'',
      universityName:null,
      addressLine1:'',
      addressLine2: '',
      pincode:'',
      state:null,
      city:null,
      country:null,
      countryOptions: [{value:null,text:'All'}],
      stateOptions: [{value:null,text:'All'}],
      cityOptions: [{value:null,text:'All'}],

      colleges:[],
      universityOptions:[{value:null,name:'Select'}],
      universityOptionsAdd:[{value:null,name:'Select'}],
      selectedCollege:'',
      selectedCollegeConfairmation:null,
      openImportMOdel:false,
      modelTitle:'',
      fileName:'',
      csvHeader: {
        'collegeName' : "G.H.Patel Post Graduation Department",
        'universityName' : "Sardar patel university",
        'addressLine1':'Vallabh Vidyanagar',
        'addressLine2':'Anand',
        'country':'India',
        'state':'Gujarat',
        'city':'Anand',
        'pincode':'388121',        
      },
      ReportTitle:['CollegeName','UniversityName','AddressLine1','AddressLine2','Country','State','City','Pincode'],
      row:'',
      fieldError:'',
      allUniversity:[],
      allFields:[],
      collegeListTable:[],
      modalLoader:false,
      gettingUniversityList:false
      // allColleges:[]
    }
  },
  created() {
    var self = this
    // this.fetchCollege();
    var countries = Country.getAllCountries()
    self.countryOptions=[{value:null,text:'All'}]
    countries.forEach(data=>{
        self.countryOptions.push({value:data,text:data.name})
    })

    // GET ALL FIELD AND COURCE
    //Get Universities

    self.$root.$on('createNewCollege',()=>{    
      self.$bvModal.show('create-college')
      self.getUniversityData().then(()=>{
      }).catch((err)=>{
        console.log(err)
      })
    })
    // getQuery.getDataWithSpecificWhereCondition(dbCollections.FIELDANDCOURSE,'isDeleted','==',false,res=>{
    //   self.allFields = []; 
    //   if(res){
    //     self.allFields =res;
    //   }
    // })
    var self = this;
    self.$root.$on('importCollegeMgmt',()=>{
      this.row='';   
      this.fieldError = '';      
      self.modelTitle="Import College List"
      self.fileName='CollegeDetails';   
      // alert("JYOTIIII")    
      // var arrData = typeof this.csvHeader != "object" ? JSON.parse(this.csvHeader) : this.csvHeader;       
      this.row = [        
              // ['CollegeName','UniversityName','AddressLine1','AddressLine2','Country','State','City','Pincode'],
              // ['G.H.Patel Post Graduation Department','Sardar Patel University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112'],
              // ['A. D. PATEL INSTITUTE OF TECHNOLOGY','Gujarat Technological University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112']
              ['CollegeName','UniversityName','AddressLine1','AddressLine2','Country','State','City','Pincode','FieldName','CourceName','ModeofApplication','Exams','IELTSScores','GMATScores','Language','GermanLanguage','Intake','SSApplicationDeadLine','WSApplicationDeadLine','Remark'],
              ['G.H.Patel Post Graduation Department','Sardar Patel University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112','BCA','java&&os&&php','direct','ielts&&gmat','7','7','english','a1','ss&&ws','20-11-21','20-11-21','Test'],
              ['G.H.Patel Post Graduation Department','Sardar Patel University','Vallabh Vidyanagar','Anand','India','Gujarat','Anand','380112','BCA','java','direct','ielts','7','','english','a1','ss','20-11-21','','Test'],
            ] 
      this.row = JSON.stringify(this.row)          
      self.openImportMOdel = true; 
    })
    self.$root.$on('exportCollege',()=>{
      let exportArray = [];
      let header = ['No','College Name','University Name','No. of Course','City','State','Country']
      exportArray.push(header)
      if(this.collegeListTable.length > 0){
        this.collegeListTable.forEach((values,index)=>{
          let tempArray = [Number(index + 1),values.college_name,values.university_name,values.no_of_course.length,values.city.name,values.state.name,values.country.name]
          exportArray.push(tempArray)
        })
      console.log(exportArray,"exportArray")  
      var str = '';
      let array = exportArray;
      var filename = 'College List';
      for (var i = 0; i < array.length; i++) { 
      let line = '';
      line =array[i].join(',');
      str += line + '\r\n';
      }
      var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });

      var link = document.createElement('a');
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      }
    })
 },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,

      createCollege,
      editCollege,

      isSpinner,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,

      refreshPage,
      // fetchCollege,
      filteredCollgeList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      collegeArray,
      nextDisabled,
      handleSortChange,
      handlePageChange,
      handleSearch
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteField,
      createCollege,
      editCollege,

      isSpinner,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      filterCountry,
      filterState,
      filterCity,   
      refreshPage,
      // fetchCollege,
      filteredCollgeList,
      isFetching,
      docCategoryTable,
      fetchMoreDataStop,
      collegeArray,
      nextDisabled,
      handleSortChange,
      handlePageChange,
      handleSearch
    }
  },  
  beforeDestroy(){
    console.log('COMPONENT UNMOUNTED SUCCESSFULLT FROM COLLEGE LIST');
    this.fetchMoreDataStop = true;
    this.collegeArray = [];
  },
  methods:{
    getUniversityData() {
      var self = this;
      return new Promise((res,rej)=>{
        try {
          self.gettingUniversityList = true;
          if(self.allUniversity.length == 0 || self.universityOptionsAdd.length == 1) {
            let searchParameters = {
                collectionName: `${dbCollections.UNIVERSITIES}`,
                search: {
                    'q'            : '*',
                    'sort_by' : 'createdAt:desc',
                    'per_page': 250
                }
            }
      
            getCollectionDataTypesense(searchParameters).then((result)=>{
                typesenseFetchAll(dbCollections.UNIVERSITIES,result.hits,result.found,searchParameters.search,false,(groupResult)=>{
                    self.allUniversity = [];
                    if(groupResult.finalArray.length) {
                      groupResult.finalArray.map(e=>e.document).forEach(doc => {
                          self.universityOptions.push({'name':doc.universityName,'id':doc.id})
                          if(!doc.isDeleted)
                          {
                            self.universityOptionsAdd.push({'name':doc.universityName,'id':doc.id})
                            self.allUniversity.push(doc)
                          }
                      });
                      self.gettingUniversityList = false;
                      res(true)
                    } else {
                      res(true)
                      self.gettingUniversityList = false;
                    }
                })
            }).catch((error)=>{
              self.gettingUniversityList = false;
              rej(error)
              console.error(error)
            });
          } else {
            res(true)
            self.gettingUniversityList = false;
          }
        } catch (error) {
          console.log(error.message);
          rej({message:'Error while getting university : getUniversityData',status:false})
        }
      })
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.isDeleted === true) return 'table-disbled-row'
    },
    deleteFieldDataAfterConfirm(data){
      this.deleteField({...data,enableUniversity:true},cb=>{
        if(cb.opt == true){
          this.$root.$emit('showToastMessage',cb.msg,'success')
        }else{
          this.$root.$emit('showToastMessage',cb.msg,'danger')
        }
      })
    }, 
    deleteFieldData(data){
      if(data.item.isDeleted == true) {
        getQuery.getDataFromRootCollectionWithDocIDV2(dbCollections.UNIVERSITIES,data.item.universityId,(res)=>{
          if(res) {
            if(res.isDeleted == true) {
              console.log('DELETED COURSE CONFIRMATION POPUP IS HERE');
              this.$bvModal.hide('delete-college')
              this.selectedCollegeConfairmation = data;
              this.$bvModal.show('delete-college-confirmation');
            } else {
              this.deleteField(data,cb=>{
                if(cb.opt == true){
                  this.$root.$emit('showToastMessage',cb.msg,'success')
                }else{
                  this.$root.$emit('showToastMessage',cb.msg,'danger')
                }
              })
            }
          }
        })
      } else {
        this.deleteField(data,cb=>{
          if(cb.opt == true){
            this.$root.$emit('showToastMessage',cb.msg,'success')
          }else{
            this.$root.$emit('showToastMessage',cb.msg,'danger')
          }
        })
      }
    },    
    getStates()
    {
      var self = this

      self.state=null
      self.city=null
      
      self.stateOptions=[{value:null,text:'All'}]
      var states = State.getStatesOfCountry(self.country?.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getCities()
    {
      var self = this

      self.city=null
      
      self.cityOptions=[{value:null,text:'All'}]
      var cities = City.getCitiesOfState(self.country?.isoCode,self.state?.isoCode)
      cities.forEach(data=>{
          self.cityOptions.push({value:data,text:data.name})
      })
    },
    getFilterStates()
    {
      var self = this

      self.filterState=null
      self.filterCity=null
      
      self.stateOptions=[{value:null,text:'All'}]
      var states = State.getStatesOfCountry(self.filterCountry?.isoCode)
      states.forEach(data=>{
        self.stateOptions.push({value:data,text:data.name})
      })
    },
    getFilterCities()
    {
      var self = this

      self.filterCity=null
      
      self.cityOptions=[{value:null,text:'All'}]
      var cities = City.getCitiesOfState(self.filterCountry?.isoCode,self.filterState?.isoCode)
      cities.forEach(data=>{
        self.cityOptions.push({value:data,text:data.name})
      })
    },
    async getData(data)
    {
      var self = this;
      await self.getUniversityData()
      self.collegeName=data.item.college_name
      self.universityName=data.item.universityId ? data.item.universityId : ''
      self.country=data.item.country
      self.getStates()
      self.state=data.item.state
      self.getCities()
      self.city=data.item.city

      self.addressLine1=data.item.address.split(", ")[0].trim()
      if(data.item.address.split(", ").length == 3)
      {
        self.pincode=data.item.address.split(", ")[2].trim()
        self.addressLine2=data.item.address.split(", ")[1].trim()
      }
      else
      {
        self.pincode=data.item.address.split(", ")[1].trim()
      }

    },
    resetData()
    {
      var self=this

      self.collegeName=''
      self.universityName=null
      self.country=null
      self.state=null
      self.city=null

      self.pincode=''
      self.addressLine1=''
      self.addressLine2=''
    },
    handleRowClick(data)
    {
      if(!data.isDeleted) {
        this.$router.push({name:'college-info',params: { id: data.id,name:data.college_name }})
      }
    },
    isOnlyDecimal(event) {
      ValidationFunction.OnlyFloatWithNumber(event);
    },
    isOnlyInteger(event) {
      ValidationFunction.OnlyIntegerNumber(event);
    },
    createNewCollege(e)
    {
      var self = this
      if(self.modalLoader == false){
        self.$refs.personalInfoForm.validate().then(success=>{
        if(success)
        {
          const queryCollege = [{ field: "collegeName", operation: "==", value: this.collegeName }];

          this.modalLoader = true;
          getCollectionData(`${dbCollections.COLLEGES}`, queryCollege, {}, 0, '', '', '', '', 0).then(resCollege => {
            if(resCollege.empty) {
              let uniName = ''
              let findInd = self.universityOptions.findIndex(ele=>{
                return self.universityName == ele.id
              })
              if(findInd > -1){
                uniName = self.universityOptions[findInd].name
              }
              var Unidata={
                collegeName:self.collegeName,
                universityName:uniName,
                universityId:self.universityName,
                addressLine1:self.addressLine1,
                addressLine2:self.addressLine2,
                pincode:self.pincode,
                state:self.state,
                city:self.city,
                country:self.country,
              }
              self.createCollege(Unidata,res=>{
                this.modalLoader = false
                self.$bvModal.hide('create-college')
                setTimeout(()=>{
                  self.resetData()
                },500)
              })
            } else {
              this.modalLoader = false;
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'College already exists.',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                },
              });
            }
          })
        }
        else
        {
          this.modalLoader = false
          e.preventDefault()
        }
      });
      }
    },
    closeCreateCollege(e){
      var self = this
      if(self.modalLoader == false){
        this.selectedCollege = '';
      }
    },
    editSelectedCollege(e)
    {
      var self = this
      if(self.modalLoader == false){      
        self.$refs.personalInfoForm.validate().then(success=>{
        if(success)
        {
          self.modalLoader = true;
          let uniName = ''
          console.log(self.universityName,"self.universityName1")
          let findInd = self.universityOptions.findIndex(ele=>{
            return self.universityName == ele.id
          })
          if(findInd > -1){
            uniName = self.universityOptions[findInd].name
          }
          console.log(self.universityName,"self.universityName")
          var Unidata={
            id:self.selectedCollege.item.id,
            index:self.selectedCollege.index,
            universityId:self.universityName,
            collegeName:self.collegeName,
            universityName:uniName,
            addressLine1:self.addressLine1,
            addressLine2:self.addressLine2,
            pincode:self.pincode,
            state:self.state,
            city:self.city,
            country:self.country
          }
          self.editCollege(Unidata,res=>{
            self.modalLoader = false;
            self.$bvModal.hide('edit-college')
            setTimeout(()=>{
              self.resetData()
            },500)
          })
        }
        else
        {
          self.modalLoader = false;
          e.preventDefault()
        }
      });
      }
    },
    closeModel(event){
      this.openImportMOdel = event     
    },
    uploadImportFile(data,cb){
      var self = this;    
      let fileObject = data 
      var saveData = [];        
      if(fileObject !== undefined){     
       importCSVData(fileObject, (res) => {
          if(res.length > 0){
            let countryObj = {};
            let stateObject = {};
            let cityObject = {};
            let isUpload = true;    
            var element = {}; 
            for (let i = 0; i < res.length; i++) { 
              if(isUpload == true){
              let courceData = {};  
              let importCountry_state = []; 
              let importState_city = [];
              element = res[i];   
              var keys = Object.keys(element);   
              if(keys.length){
                  let cllgName = null;
                  let UniversityName = null;
                  let AddressLine1 = null;
                  let AddressLine2 = null;
                  let Country = null;
                  let State = null;
                  let City = null;
                  let Pincode = null;
                  let FieldName = null;
                  let CourceName = null;
                  let ModeofApplication = null;
                  let Exams = null;
                  let Language = null
                  let GermanLanguage = null
                  let Intake = null
                  let Remark = null
                for(let j = 0; j < keys.length; j++){
                  if(keys[j]==''|| keys[j]==undefined){
                    self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                    this.isSppiner = false;
                    this.$root.$emit('errorUploadAgain')
                    cb(false)
                    isUpload = false;                
                    return; 
                  }                  
                  let lastIndex = Number(keys.length - 1);
                  if(keys[j] == 'CollegeName'){
                    cllgName = 'CollegeName'
                  }
                  if(keys[j]=='UniversityName'){
                    UniversityName='UniversityName'
                  }
                  if(keys[j]=='AddressLine1'){
                    AddressLine1='AddressLine1'
                  }
                  if(keys[j]=='AddressLine2'){
                    AddressLine2='AddressLine2'
                  }
                  if(keys[j]=='Country'){
                    Country="Country"
                  }
                  if(keys[j]=='State'){
                    State="State"
                  }
                  if(keys[j]=='City'){
                    City='City'
                  }
                  if(keys[j]=='Pincode'){
                    Pincode='Pincode'
                  }
                  if(keys[j]=='FieldName'){
                    FieldName='FieldName'
                  }
                  if(keys[j]=='CourceName'){
                    CourceName="CourceName"
                  }
                  if(keys[j]=='ModeofApplication'){
                    ModeofApplication="ModeofApplication"
                  }
                  if(keys[j]=='Exams'){
                    Exams="Exams"
                  }
                  if(keys[j]=='Language'){
                    Language="Language"
                  }
                  if(keys[j]=='GermanLanguage'){
                    GermanLanguage="GermanLanguage"
                  }
                  if(keys[j]=="Intake"){
                    Intake="Intake"
                  }
                  if(keys[j]=="Remark"){
                    Remark="Remark"
                  }                  
                  if(lastIndex == j){
                    if(cllgName == null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return; 
                    }   
                    if(UniversityName == null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(AddressLine1==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }  
                    if(AddressLine2==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Country==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(State==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(City==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Pincode==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(FieldName==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(CourceName==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(ModeofApplication==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Exams==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Language==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(GermanLanguage==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Intake==null){
                      self.fieldError = "Invalid column name, [ please verify download-sample-file and upload file again ]";
                      this.isSppiner = false;              
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                    }
                    if(Remark==null){}
                    break;
                  }
                }
              }else{
                self.fieldError = "Empty file not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return;
              }  
              // CHECK EXAMS VALIDATION          
              if(element.Exams==""||element.Exams==undefined){
                self.fieldError = "Invalid Exams column values, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return; 
              }else{                          
                let getExams = element.Exams.split('&&')
                if(getExams.length > 0){ 
                  getExams.forEach(val=>{
                    if(isUpload!==false){
                      let findHeader = val.toUpperCase()+'Scores'
                      let isAble = false;
                      let keuInd = '';                
                      if(val.toUpperCase() !== 'IELTS' && val.toUpperCase() !== 'GMAT' && val.toUpperCase() !== 'TOEFL' && val.toUpperCase() !== 'MCAT'){
                          this.fieldError = "Invalid Exams name, [Valid Exams like- IELTS,GMAT,TOEFL,MCAT ]";
                          this.isSppiner = false;
                          this.$root.$emit('errorUploadAgain')
                          cb(false)
                          isUpload = false;                
                          return; 
                      }   
                      if(keys.length > 0){
                        for(let i = 0; i <= keys.length; i++){
                          if(findHeader == keys[i]){
                            isAble = true;  
                            keuInd = i;
                            break;
                          }else{
                            isAble = false;  
                          }
                        }
                      }
                      if(isAble == false){
                              this.fieldError = `Invalid ${val} column name, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                      }else{                       
                        let getExamScore = keys[keuInd];
                        getExamScore.toString().replace('Scores', '');       
                        if(keuInd > -1){
                        if(keys[keuInd]=='IELTSScores'){
                          if(element.IELTSScores == ''|| element.IELTSScores == undefined){
                              this.fieldError = `Invalid IELTSBand/Score value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }
                        if(keys[keuInd]=='GMATScores'){
                          if(element.GMATScores == ''|| element.GMATScores == undefined){
                              this.fieldError = `Invalid GMATBand/Score value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }
                        if(keys[keuInd]=='TOEFLScores'){
                          if(element.TOEFLScores == ''|| element.TOEFLScores == undefined){
                              this.fieldError = `Invalid TOEFLBand/Score value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }
                        if(keys[keuInd]=='MCATScores'){
                          if(element.MCATScores == ''|| element.MCATScores == undefined){
                              this.fieldError = `Invalid MCATBand/Score value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }
                        }
                      }  
                    }  
                  })
                }
              } 
              // CHECK INTAKE VALIDATION
              if(element.Intake !== null || element.Intake !== undefined){
                let getIntake = element.Intake.split('&&')
                if(getIntake.length > 0){ 
                  getIntake.forEach(val=>{
                    if(isUpload!==false){
                      let findHeader = val.toUpperCase()+'ApplicationDeadLine'
                      let isAble = false;
                      let keuInd = '';                
                      if(val.toUpperCase() !== 'SS' && val.toUpperCase() !== 'WS'){
                          this.fieldError = "Invalid intake values, [Valid Exams like- IELTS,GMAT,TOEFL,MCAT ]";
                          this.isSppiner = false;
                          this.$root.$emit('errorUploadAgain')
                          cb(false)
                          isUpload = false;                
                          return; 
                      }   
                      if(keys.length > 0){
                        for(let i = 0; i <= keys.length; i++){
                          if(findHeader == keys[i]){
                            isAble = true;  
                            keuInd = i;
                            break;
                          }else{
                            isAble = false;  
                          }
                        }
                      }
                      if(isAble == false){
                              this.fieldError = `Invalid ${val} column name, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                      }else{                       
                        let getExamScore = keys[keuInd];
                        getExamScore.toString().replace('ApplicationDeadLine', '');                      
                        if(keuInd > -1){
                        if(keys[keuInd]=='SSApplicationDeadLine'){
                          if(element.SSApplicationDeadLine == ''|| element.SSApplicationDeadLine == undefined){
                              this.fieldError = `Invalid ss deadline date value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }
                        if(keys[keuInd]=='WSApplicationDeadLine'){
                          if(element.WSApplicationDeadLine == ''|| element.WSApplicationDeadLine == undefined){
                              this.fieldError = `Invalid ws deadline date value, [ please verify download-sample-file and upload file again ]`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return;
                          }
                        }                      
                        }
                      }  
                    }  
                  })
                }
              }else{
                self.fieldError = "Invalid intake column values, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return; 
              }                            
              if(element.Language == null || element.Language == undefined){
                this.fieldError = "Language field is require [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return;
              }
              if(element.CollegeName=="" || element.UniversityName=="" || element.AddressLine1=="" || element.AddressLine2=="" || element.Country=="" || element.State=="" || element.City=="" || element.Pincode=="" || element.FieldName=="" || element.CourceName=="" || element.ModeofApplication=="" || element.GermanLanguage=="" || element.Intake=="" || element.Remark == "" || element.Language==""){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return; 
              } 
              if(element.CollegeName==undefined || element.UniversityName==undefined || element.AddressLine1==undefined || element.AddressLine2==undefined || element.Country==undefined || element.State==undefined || element.City==undefined || element.Pincode==undefined || element.FieldName==undefined || element.CourceName==undefined || element.ModeofApplication==undefined || element.GermanLanguage==undefined || element.Intake==undefined || element.Remark==undefined || element.Language==undefined){
                self.fieldError = "Empty sheel not allow, [ please verify download-sample-file and upload file again ]";
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return; 
              } 
              // MATCH UNIVERSITY  
              let findUniInd = self.allUniversity.findIndex(uniData=>{
                return uniData.universityName.toLowerCase() == element.UniversityName.toLowerCase();
              })   
              if(findUniInd <= -1){
                self.fieldError = `${element.UniversityName} not exist in system collection`; 
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return;                 
              }else{
                element.UniversityName = self.allUniversity[findUniInd].universityName;
              }
              // COUNTRY STATE CITY VALIDATION   
              if(self.countryOptions.length > 0){
                let countryInd = self.countryOptions.findIndex(val=>{
                  if(val.value !== null){
                    return val.value.name.toLowerCase() == element.Country.toLowerCase()
                  }
                })               
                if(countryInd > -1){
                  countryObj = self.countryOptions[countryInd].value;                   
                  // set State Array
                  var statesArray = State.getStatesOfCountry(self.countryOptions[countryInd].value.isoCode)
                  statesArray.forEach(data=>{
                    importCountry_state.push({value:data,text:data.name})
                  })
                  if(importCountry_state.length > 0){
                      let searchStateInd = importCountry_state.findIndex(stateVal=>{
                        if(stateVal.value !== null){
                          return stateVal.value.name.toLowerCase() == element.State.toLowerCase()
                        }                        
                      })
                      if(searchStateInd > -1){
                           stateObject =  importCountry_state[searchStateInd].value;
                           var cities = City.getCitiesOfState(self.countryOptions[countryInd].value.isoCode,importCountry_state[searchStateInd].value.isoCode)
                            cities.forEach(data=>{
                                importState_city.push({value:data,text:data.name})
                            })
                            if(importState_city.length > 0){
                                let findCity = importState_city.findIndex(cityVal=>{
                                  if(cityVal.value !== null){
                                    return cityVal.value.name.toLowerCase() == element.City.toLowerCase()
                                  }
                                })
                                if(findCity > -1){
                                  cityObject = importState_city[findCity].value; 
                                }else{
                                    self.fieldError = `Invalid city name ${element.State}`;
                                    this.isSppiner = false;
                                    this.$root.$emit('errorUploadAgain')
                                    cb(false)
                                    isUpload = false;                
                                    return; 
                                }
                            }else{
                              self.fieldError = `Invalid city name ${element.State}`;
                              this.isSppiner = false;
                              this.$root.$emit('errorUploadAgain')
                              cb(false)
                              isUpload = false;                
                              return; 
                            }
                      }else{
                        self.fieldError = `Invalid state name ${element.State}`;
                        this.isSppiner = false;
                        this.$root.$emit('errorUploadAgain')
                        cb(false)
                        isUpload = false;                
                        return; 
                      }
                  }else{
                    self.fieldError = `Invalid country name ${element.Country}`;
                    this.isSppiner = false;
                    this.$root.$emit('errorUploadAgain')
                    cb(false)
                    isUpload = false;                
                    return;
                  }
                }else{
                    self.fieldError = `Invalid country name ${element.Country}`;
                    this.isSppiner = false;
                    this.$root.$emit('errorUploadAgain')
                    cb(false)
                    isUpload = false;                
                    return;
                }             
              
              }  
              // MATCH FIELD
              if(element.FieldName !== ""||element.FieldName !== undefined){               
                if(this.allFields.length > 0){
                  let findFieldInd = this.allFields.findIndex(fieldVal=>{
                    return fieldVal.fieldName.toLowerCase() == element.FieldName.toLowerCase()
                  })
                  if(findFieldInd > -1){
                    element.FieldName = this.allFields[findFieldInd].fieldName;
                    if(element.CourceName !== undefined){
                    let fieldsCources = element.CourceName.split('&&')
                    courceData = this.allFields[findFieldInd]; 
                    let courceArry = this.allFields[findFieldInd].courses;
                    if(courceArry.length > 0){
                      fieldsCources.forEach(courceVals=>{
                          let courceInd = courceArry.findIndex(courceVall=>{
                            return courceVall.toLowerCase() == courceVals.toLowerCase()
                          })
                          if(courceInd > -1){
                            // element.CourceName = courceArry[courceInd]
                            courceArry[courceInd] = courceArry[courceInd];
                          }else{
                            self.fieldError = `Field Name-${courceVals} not exist in system collection`; 
                            this.isSppiner = false;
                            this.$root.$emit('errorUploadAgain')
                            cb(false)
                            isUpload = false;                
                            return; 
                          }
                          })                      
                        }
                    }
                  }else{
                      self.fieldError = `${element.FieldName} not exist in system collection`; 
                      this.isSppiner = false;
                      this.$root.$emit('errorUploadAgain')
                      cb(false)
                      isUpload = false;                
                      return;
                  }
                }
              }
              //Match Language
              let langArry = ['English','Gujarati','Sanskrit','Hindi'];
              let langInd = langArry.findIndex(langVal=>{
                return langVal.toLowerCase() == element.Language.toLowerCase()
              })
              if(langInd > -1){
                element.Language = langArry[langInd];
              }else{
                self.fieldError = `${element.Language} not exist in system collection`; 
                this.isSppiner = false;
                this.$root.$emit('errorUploadAgain')
                cb(false)
                isUpload = false;                
                return;
              }  
              // if(saveData.length <= 0){          
              saveData.push({
                'fileData':element,
                'countryObj':countryObj,
                'stateObject':stateObject,
                'cityObject':cityObject,
                'courceData':courceData
              }) 
              // }else{
              //   let findSameCllg = saveData.findIndex(values=>{
              //     return values.fileData.CollegeName == element.CollegeName
              //   })
              //   if(findSameCllg > -1){
              //     saveData[findSameCllg]
              //   }else{
              //     saveData.push({
              //       'fileData':element,
              //       'countryObj':countryObj,
              //       'stateObject':stateObject,
              //       'cityObject':cityObject,
              //       'courceData':courceData
              //     }) 
              //   }
              // }            
              }
            }
            if(isUpload){ 
              console.log(saveData,"Result")
              cb(saveData)
            }
          }
          else {
            self.fieldError = "Empty file can not be allowed";        
            self.isSppiner = false   
            return;
          }
      })    
     }     
    },
    addcllgData(data,cbb){
      if(data !== undefined){          
        let courceArry = [];   
        let courceNameArry = data.fileData.CourceName.split('&&');
        let intakeArry = data.fileData.Intake.split('&&');
        let examsArry = data.fileData.Exams.split('&&');
        let applicationDeadline = [];
        let exams = [];
        if(this.colleges.length > 0){
          let samecollege = this.colleges.findIndex(vaal=>{
            return vaal == data.fileData.CollegeName
          }) 
          if(samecollege <= -1){
                 let fieldName = {
                    'name': data.fileData.FieldName,
                    'id': data.courceData.id,
                    'courses': data.courceData.courses
                  };
          if(intakeArry.length > 0){
            intakeArry.forEach(intakeVall=>{
              // let fileHeader = intakeVall.toUpperCase()+'ApplicationDeadLine';
              if(intakeVall.toLowerCase() == 'ss'){
                applicationDeadline.push({
                  'title':intakeVall.toUpperCase(),
                  'date':data.fileData.SSApplicationDeadLine
                })
              }else{
                applicationDeadline.push({
                  'title':intakeVall.toUpperCase(),
                  'date':data.fileData.WSApplicationDeadLine
                })
              }
            })
          }
          if(examsArry.length > 0){
            examsArry.forEach(exmVal=>{
              if(exmVal.toLowerCase()=='IELTS'){
                exams.push({
                  'score':data.fileData.IELTSScores,
                  'title':exmVal.toUpperCase()
                })
              }
              if(exmVal.toLowerCase()=='GMAT'){
                exams.push({
                  'score':data.fileData.GMATScores,
                  'title':exmVal.toUpperCase()
                })
              }
              if(exmVal.toLowerCase()=='TOEFL'){
                exams.push({
                  'score':data.fileData.TOEFLScores,
                  'title':exmVal.toUpperCase()
                })
              }
              if(exmVal.toLowerCase()=='MCAT'){
                exams.push({
                  'score':data.fileData.MCATScores,
                  'title':exmVal.toUpperCase()
                })
              }
            })
          }
          if(courceNameArry.length > 0){
            courceNameArry.forEach(val=>{
              courceArry.push({
                'applicationDeadline':applicationDeadline,
                'courseName':val,
                'exams':examsArry,
                'fieldName':fieldName,
                'germanLanguage':data.fileData.GermanLanguage,
                'intake': intakeArry,
                'language':data.fileData.Language,
                'modeOfApplication':data.fileData.ModeofApplication,
                'remark':data.fileData.Remark,
              })
            })
          }
          let cllgObj = {
                'isDeleted':false,
                'createdAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                'universityName':data.fileData.UniversityName,
                'courses':courceArry,
                'collegeName':data.fileData.CollegeName,
                'city':data.cityObject,
                'state':data.stateObject,
                'country':data.countryObj,
                'addressLine1':data.fileData.AddressLine1,
                'addressLine2':data.fileData.AddressLine2,
                'pincode':data.fileData.Pincode,
          }
          inserQuery.addDataToRootCollection(dbCollections.COLLEGES,cllgObj,cllgres=>{ 
              let updateId={
                  'id':cllgres.id,
                  'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
              }
              updateQuery.updateRootCollectionDataByDocId(dbCollections.COLLEGES,cllgres.id,updateId,updateCllgRes=>{
                this.colleges.push(data.fileData.CollegeName)  
                cbb(true)         
              })
          }) 
          }else{           
            this.fieldError = "College Name already exist in system..";
            this.isSppiner = false;
            this.$root.$emit('errorUploadAgain')
            cbb(false)           
            return
          }
        }
     }
     else if(data == false){
         cbb(data)
     }else{
       cbb(true)
     } 
    },
    // FIND ARRAY DUPLICATE
    checkIfArrayIsUnique(myArray,cb) 
    {
        for (var i = 0; i < myArray.length; i++) 
        {
            for (var j = 0; j < myArray.length; j++) 
            {
                if (i != j) 
                {
                    if (myArray[i].fileData.CollegeName == myArray[j].fileData.CollegeName) 
                    {
                      let same = {
                        'firstSame':i,
                        'secondSame':j
                      }
                        cb(same); // means there are duplicate values
                        break;
                    }
                }
            }
        }
        cb(false); // means there are no duplicate values.        
    },
    saveCllgData(data,res){
       if(data && data.length > 0){
          let count = 0;
          let fileArry = data;
          const uploadFileFunction = (row) => {                             
               this.addcllgData(row,(saveRes)=>{ 
                 console.log('saveCllgData',saveRes)                
                  if(count > Number(fileArry.length-1)){
                    res(true)
                    return
                  }else{ 
                  if(saveRes == true){
                  count += 1;
                  uploadFileFunction(fileArry[count]);
                  }
                  if(saveRes == false){
                    res(false)
                    return;
                  }
                  }
              });              
          }
          uploadFileFunction(fileArry[count]);            
        }
    },
    importCsvFile(event){
       if(event.length > 0){
        this.isSppiner = true;
          let count = 0;
            const uploadFileFunction = (row) => {  
                this.uploadImportFile(row,(saveRes)=>{                   
                    if(saveRes == false)
                    {
                      this.$root.$emit('errorUploadAgain')
                      this.isSppiner = false;
                      return;
                    }
                    else{   
                      this.saveCllgData(saveRes,saveCb=>{    
                          if(count > parseInt(Number(event.length)-1))
                          {  
                            this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Document Add Successfully',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                            })  
                            this.refreshPage(res=>{
                              console.log(res,"JYOTI")
                            });
                            this.$root.$emit('importFileDone')                          
                            this.isSppiner = false;
                            return;                  
                          }
                          else
                          {
                            if(saveCb==true){
                            count += 1;
                            uploadFileFunction(event[count]);
                            }else{
                              return
                            }
                          }
                      })
                   }
                });              
            }
            uploadFileFunction(event[count]);
      } 
    },
  },
  computed:{
    duplicateCollegeName()
    {
      var self = this
      var found = false

      self.colleges.forEach((data,index)=>{
        if(data.toLowerCase().trim()==self.collegeName.toLowerCase().trim())
        {
          found = true
        }
      })

      return found;
    },
    // duplicateEditCollegeName()
    // {
    //   var self = this
    //   var found = false     
    //   self.allColleges.forEach((data,index)=>{
    //     if(data.cllgName.toLowerCase().trim()==self.collegeName.toLowerCase().trim())
    //     {
    //       // found = true;
    //       if(Number(index + 1) == self.allColleges){
    //         return;
    //       }
    //       if(data.id.toString() == self.selectedCollege.item.id.toString()){
    //         found = false;
    //       }else{
    //       found = true;
    //       }
    //     }
    //   })  
        
    //   return found;
    // },   
  }, 
}
</script>
<style>
.focusRemoveToggle:focus {
  outline: none !important;
}
.table-disbled-row {
  cursor: default !important;
}
</style>
<style scoped>
.card .card-body h4.card-title{
  border-bottom:none !important;
  margin: 0px -20px 0px -14px !important;
}
.tableFilters .form-group{
  margin-bottom: 0px !important;
}
</style>
<style>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}
/* .position-relative.table-responsive tbody tr td{
  white-space: nowrap !important;
} */

.s_modal_title{
    width: 100%;
    
    font-weight: 600;
    font-size: 20px;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
