<template>
    <div>
        <b-modal
        v-model="openImportMOdel"    
        :title="modelTitle"
        ok-title="Import"
        centered       
        cancel-title='Cancel'        
        @close.prevent='closeModel'
        @cancel.prevent='closeModel'
        @ok.prevent='$refs.inputFile.click()'  
        :ok-disabled='isSppiner'
        :cancel-disabled='isSppiner'   
        >
        <b-spinner variant="primary" v-if="isSppiner" large class="pageLoadSpinners"/>

                    <!-- <a href="javascript:void(0)" class="model-close" @click="errorMessage.csvFileUploadMsg = '', openImportMOdel = false"><i class="vs-icon notranslate  material-icons null">close</i></a> -->
                    <h4 class="import-csv-title">Follow these steps:</h4>
                    <ul>
                      <li>1. Download the CSV template to your computer by clicking the Download button.</li>
                      <li>2. Integrate your data into the template file.</li>
                      <li>3. Click the Import button.</li>
                    </ul>
                    <h4 class="import-csv-title">IMPORTANT</h4> 
                    <p>
                       If you do not have data for a particular column, leave it empty and do not move or delete or remane the column. This operation will overwrite your all data.
                    </p>
                    <div class='error'>{{errorMessage.fileExtensionError}}</div>
                    <div class='error'>{{fieldError}}</div>
                    <div class="buttons d-flex">
                      <button class="btn btn-primary" @click="downloadFormatCsv()" :disabled='isSppiner'>Download Sample File</button>
                      <!-- <button class="btn btn-primary btn-second" @click="$refs.inputFile.click()">Import</button>-->
                      <input type="file" ref="inputFile" id="fileOutput" style="display: none" @change="importCostType()">                       
                    </div>                    
        </b-modal>
    </div>
</template>
<script>
import {BSpinner} from 'bootstrap-vue'
export default{
  components: {
    BSpinner,
  },
  data(){
      return{
          fileImport:{},
          errorMessage: {
            fileExtensionError: "",
            fieldsError: "",       
            csvFileUploadMsg: false
          },
      }
  },
  props:{
      openImportMOdel:Boolean,
      modelTitle:String,
      fileName:String,
      row:String,
      ReportTitle:Array,
      fieldError:String,
      isSppiner:Boolean
  }, 
  created(){
      this.$root.$on('importFileDone',()=>{
          this.$emit('closeImportCsvModel',false)
          // this.$refs.inputFile.files = [];
          this.$refs.inputFile.value = "";
          this.resetData();
      })
      this.$root.$on('errorUploadAgain',()=>{
        // this.$refs.inputFile.files = [];
        this.$refs.inputFile.value = "";
        this.$emit('importCsvFile',this.$refs.inputFile.files)
      })
  },
  methods:{
      downloadFormatCsv(){   
          // var array = [
          // [1,2,3],
          // [4,5,6],
          // [7,8,9]
          // ];
          var array = JSON.parse(this.row);
          var str = '';
          for (var i = 0; i < array.length; i++) { 
          let line = '';
          line =array[i].join(',');
          str += line + '\r\n';
          }
          var blob = new Blob([str], { type: 'text/csv;charset=utf-8;' });

          var link = document.createElement('a');
          var url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', this.fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
            // // var arrData = typeof this.csvHeader != "object" ? JSON.parse(this.csvHeader) : this.csvHeader;
            // var CSV = '';              
            // CSV += this.ReportTitle + '\r';
            // var row = JSON.parse(this.row); 
            // // row += [  
            // //     arrData.value,
            // //     arrData.description,
            // // ]
            // row.slice(0, row.length - 1);
            //     CSV += row + "\n";
            //     var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
            //     var link = document.createElement("a");    
            //     link.href = uri;
            //     link.style = "visibility:hidden";
            //     link.download = this.fileName;
            //     document.body.appendChild(link);
            //     link.click();
            //     document.body.removeChild(link);  
      },
      closeModel(){
        if(this.isSppiner==false){
          this.$emit('closeImportCsvModel',false)
          this.resetData(); 
        }
      },
      importCostType(){
        var self = this;    
        this.fieldError = ""  
        if(self.isSppiner==false){
        self.fileImport = self.$refs.inputFile.files;
        let fileObject = self.$refs.inputFile.files[0]
        const filename = fileObject.name;
        const ext = filename.split('.').pop();
        if(ext != "csv"){
            self.errorMessage.fileExtensionError = "Only .csv file upload allowed";
            this.$refs.inputFile.value = null;
            return;
        }else{       
          this.$emit('importCsvFile',self.$refs.inputFile.files)          
        }
        }else{
          console.log("Refresh the page....")
        }  
      },
      resetData(){
          console.log("RESET")
            this.$refs.inputFile.value = "";
            this.fileImport={};
            this.errorMessage= {
            fileExtensionError: "",
            fieldsError: "",       
            csvFileUploadMsg: false
          };
      }     
  },
  computed:{
    //   closeModel(){
    //       if(this.openImportMOdel==false){
    //        return this.$emit('closeImportCsvModel',false)
    //       }
    //   }
    // importFileModel(){        
    //     if(this.openImportMOdel == true){
    //         return this.openImportMOdel;
    //     }else{
    //         this.$emit('closeImportCsvModel',false)
    //         return this.openImportMOdel
    //     }
    // }
  },
//   mounted() {
//     this.closeEvent()
//   }, 
}
</script>
<style>
.error{
    padding: 15px;
    display: inline-flex;
    color: red;
}
  /* #importFileModel .btn-close{
         display: none;
     } */
</style>
