const applications = {
    "default_sorting_field": "createdAt",
    "enable_nested_fields": true,
    "fields": [
        {
            "facet": true,
            "name": "applicationNumber",
            "sort": true,
            "type": "string"
        },
        {
            "facet": true,
            "name": "application_Status",
            "sort": true,
            "type": "string"
        },
        {
            "facet": true,
            "name": "createdAt",
            "sort": true,
            "type": "int32"
        },
        {
            "facet": true,
            "name": "updatedAt",
            "sort": true,
            "type": "int32"
        },
        {
            "facet": true,
            "name": "id",
            "type": "string"
        },
        {
            "facet": true,
            "name": "intake",
            "type": "string[]",
            "facet": true
        },
        {
            "facet": true,
            "name": "isActive",
            "sort": true,
            "type": "bool"
        },
        {
            "facet": true,
            "name": "isDeleted",
            "sort": true,
            "type": "bool"
        },
        {
            "facet": true,
            "name": "selectedCollege",
            "sort": false,
            "type": "auto"
        },
        {
            "facet": true,
            "name": "selectedStudent",
            "sort": false,
            "type": "auto"
        },
        {
            "facet": true,
            "name": "selectedStudent.name",
            "sort": true,
            "type": "string"
        },
        {
            "facet": true,
            "name": "shortListedColleges",
            "sort": false,
            "optional": true,
            "type": "auto"
        },
        {
            "facet": true,
            "name": "applicationClose",
            "sort": false,
            "optional": true,
            "type": "bool"
        },
        {
            "facet": true,
            "name": "finalSelected",
            "sort": false,
            "optional": true,
            "type": "auto"
        },
    ],
}

const colleges = {
    "enable_nested_fields": true,
    "default_sorting_field": "createdAt",
    "fields": [
        {
            "name": "addressLine1",
            "type": "string",
            "facet": true
        },
        {
            "name": "addressLine2",
            "type": "string",
            "facet": true,
            "optional":true
        },
        {
            "name": "city",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "city.name",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "collegeName",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "country",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "country.name",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "createdAt",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "updatedAt",
            "type": "auto",
            "facet": true
        },
        {
            "name": "id",
            "type": "string",
            "facet": true
        },
        {
            "name": "isDeleted",
            "type": "bool",
            "facet": true
        },
        {
            "name": "pincode",
            "type": "string",
            "facet": true
        },
        {
            "name": "state",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "state.name",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "universityName",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "universityId",
            "type": "string",
            "facet": true
        },

    ]
}

const courses = {
    "enable_nested_fields": true,
    fields: [
        {
            "name": "applicationDeadline",
            "type": "auto",
            "facet": true,
        },
        {
            "name": "applicationFees",
            "type": "string",
            "sort": true,
            "facet": true,
            "optional":true
        },
        {
            "facet": true,
            "name": "collegeId",
            "optional": true,
            "type": "string"
        },
        {
            "facet": true,
            "name": "collegeName",
            "sort": true,
            "type": "string"
        },
        {
            "name": "courseUniqueId",
            "type": "string",
            "facet": true,
            "optional":true
        },
        {
            "name": "exams",
            "type": "auto",
            "facet": true,
        },
        {
            "name": "fieldName",
            "type": "auto",
            "facet": true
        },
        {
            "name": "fieldName.name",
            "type": "string",
            "facet": true,
            "sort": true
        },
        {
            "name": "courseName",
            "type": "auto",
            "facet": true
        },
        {
            "name": "courseName.title",
            "type": "string",
            "facet": true,
            "sort": true,
        },
        {
            "name": "germanLanguage",
            "type": "string",
            "facet": true,
            "sort": true,
            "optional":true
        },
        {
            "name": "id",
            "type": "string",
            "facet": true
        },
        {
            "name": "intake",
            "type": "string[]",
            "facet": true
        },
        {
            "name": "language",
            "type": "auto",
            "facet": true,
            "optional":true
        },
        {
            "name": "modeOfApplication",
            "type": "string",
            "sort": true,
            "facet": true
        },
        {
            "name": "remark",
            "type": "string",
            "sort": true,
            "facet": true,
            "optional":true
        },
        {
            "name": "tutionFees",
            "type": "string",
            "sort": true,
            "facet": true,
            "optional":true
        },
        {
            "facet": true,
            "name": "universityId",
            "optional": true,
            "type": "string"
        },
        {
            "facet": true,
            "name": "universityName",
            "optional": true,
            "sort": true,
            "type": "string"
        },
        {
            "name": "isDeleted",
            "type": "bool",
            "facet": true,
            "optional":true
        },
        {
            "facet": true,
            "index": true,
            "infix": false,
            "locale": "",
            "name": "createdAt",
            "optional": true,
            "sort": true,
            "type": "int32"
        },
        {
            "facet": true,
            "index": true,
            "infix": false,
            "locale": "",
            "name": "updatedAt",
            "optional": true,
            "sort": true,
            "type": "int32"
        }
    ]
}

const university = {
    "enable_nested_fields": true,
    "default_sorting_field": "createdAt",
    "fields": [
        {
            "name": "addressLine1",
            "type": "string",
            "facet": true
        },
        {
            "name": "addressLine2",
            "type": "string",
            "facet": true,
            "optional":true
        },
        {
            "name": "city",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "city.name",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "country",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "country.name",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "createdAt",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "updatedAt",
            "type": "auto",
            "facet": true
        },
        {
            "name": "id",
            "type": "string",
            "facet": true
        },
        {
            "name": "isDeleted",
            "type": "bool",
            "facet": true
        },
        {
            "name": "isInvoiceGenerated",
            "type": "bool",
            "facet": true,
            "optional":true
        },
        {
            "name": "pincode",
            "type": "string",
            "facet": true
        },
        {
            "name": "state",
            "type": "auto",
            "facet": true,
            "sort":true
        },
        {
            "name": "state.name",
            "type": "string",
            "facet": true,
            "sort":true
        },
        {
            "name": "universityName",
            "type": "string",
            "facet": true,
            "sort":true
        },
    ]
}
module.exports = {
    applications,
    colleges,
    courses,
    university
}