//READ CSV DATA
export const importCSVData = (fileObject, cb) => {
    const reader = new FileReader()
    const csv = fileObject;
    reader.readAsText(csv)
    reader.onload = function (e) {
      var temp = e.target.result
      var lines = temp.split("\r\n");
      var result = [];
      var headers = lines[0].split(",");
      for(var i = 1; i < lines.length; i++){
          var obj = {};
          var currentline = (lines[i] + ',').split(/(?: *?([^",]+?) *?,|" *?(.+?)" *?,|( *?),)/).slice(1).reduce((a, b) => (a.length > 0 && a[a.length - 1].length < 4) ? [...a.slice(0, a.length - 1), [...a[a.length - 1], b]] : [...a, [b]], []).map(e => e.reduce((a, b) => a !== undefined ? a : b, undefined))
          for(var j = 0; j < headers.length; j++){         
              obj[headers[j]] = currentline[j];
          }
          result.push(obj); 
      }
      let finalData = [];
      for (let i = 0; i < result.length; i += 1) {
        if ((result.length-1) != i) {
          finalData.push(result[i]);
        }
      }
      cb(finalData);
    }
  }