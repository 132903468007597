import { Client } from 'typesense';

// Create a new client
let client = {};

client = new Client({
    nodes: [{
        host: 'c6jnx3eqpmfd1k9tp-1.a1.typesense.net',
        port: '443',
        protocol: 'https',
    }],
    apiKey: 'D1qxLfddGKjJKHH7aOqhr8vmafkzCL5i',
    connectionTimeoutSeconds: '2',
});

export default client;